import { required } from "@vuelidate/validators";
import CategoryBond from "./category_bond";
import Occupation from "./occupation";
import Office from "./office";
import Person from "./person";
import Regiment from "./regiment";
import Sector from "./sector";
import Situation from "./situation";
import TypeBond from "./type_bond";

export default class Bond {
    constructor() {
        this.id = null;
        this.numberBond = null;
        this.dtExercise = null;
        this.dtInit = null;
        this.dtEnd = null;
        this.categoryBond = new CategoryBond();
        this.typeBond = new TypeBond();
        this.office = new Office();
        this.situation = new Situation();
        this.regiment = new Regiment();
        this.occupation = new Occupation();
        this.person = new Person();
        this.acting = new Sector();
        this.capacity = new Sector();
    }
    validations() {
        return {
            numberBond: {
                required,
            },
            dtExercise: {
                required,
            },
            dtInit: {
                required,
            },
            categoryBond: {
                id: {
                    required,
                },
            },
            typeBond: {
                id: {
                    required,
                },
            },
            situation: {
                id: {
                    required,
                },
            },
            regiment: {
                id: {
                    required,
                },
            },
            office: {
                id: {
                    required
                },
            },
            occupation: {
                id: {
                    required
                },
            },
            person: {
                required,
            },
            acting: {
                id: {
                    required,
                },
            },
            capacity: {
                id: {
                    required,
                },
            },
        };
    }
}