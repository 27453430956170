<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '750px' }"
    :header="'JORNADAS DE TRABALHO | ' + bondSelected.person.name"
    :modal="true"
    @hide="hideDialog"
  >
    <Toolbar>
      <template #start>
        <Button
          label="Novo"
          icon="pi pi-plus"
          class="p-button-success"
          @click="showCreate"
        />
      </template>

      <template #end></template>
    </Toolbar>

    <DataTable :value="workdays">
      <template #empty>
        <div class="text-center">Nenhum resultado encontrado...</div>
      </template>
      <Column field="entrance" header="Entrada">
        <template #body="slotProps">{{ slotProps.data.entrance }}</template>
      </Column>
      <Column field="exit" header="Saída">
        <template #body="slotProps">{{ slotProps.data.exit }}</template>
      </Column>
      <Column field="ch" header="Carga Horária">
        <template #body="slotProps">{{ slotProps.data.ch }} hora(s) </template>
      </Column>
      <Column field="color" header="Ações">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mr-2"
            @click="showUpdate(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EDITAR'"
          />
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <Button
        label="Fechar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
  <dialog-form-workday
    :bondSelected="bondSelected"
    :workdaySelected="workday"
    @findAll="getData"
  />
</template>

<script>
//Models
import Bond from "@/models/bond";
import Workday from "@/models/workday";

//Services
import BondService from "@/service/bond/bond_service";
import WorkdayService from "@/service/workday/workday_service";

//Components
import DialogFormWorkday from "./dialog-form-workday.vue";

export default {
  props: ["bondSelected"],
  components: {
    DialogFormWorkday,
  },
  data() {
    return {
      bondService: new BondService(),
      workdayService: new WorkdayService(),
      bond: new Bond(),
      workday: new Workday(),
      workdays: [],
      selectedBond: null,
      selectAll: false,
    };
  },
  mounted() {},
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogWorkday;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogWorkday = value;
      },
    },
  },
  methods: {
    getData() {
      if (this.bondSelected) {
        this.bond = this.bondSelected;
        this.workdayService.findByBondId(this.bond.id).then((response) => {
          this.workdays = response.data;
        });
      }
    },
    showCreate() {
      this.workday = new Workday();
      this.$store.state.views.bond.dialogFormWorkday = true;
    },
    showUpdate(workday) {
      this.workday = workday;
      this.$store.state.views.bond.dialogFormWorkday = true;
    },
    hideDialog() {
      this.workday = new Workday();
      this.submitted = false;
      this.visibleDialog = false;
    },
  },
};
</script>

<style></style>
