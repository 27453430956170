import axios from "axios";
import Token from "../../utilities/jwt/Token.js";
import { gerarUrl} from  "../../utilities/utils/Pageable";

export default class SectorService extends Token {
  findAll(queryParams) {

    queryParams = gerarUrl(queryParams);

    return axios({
      method: "get",
      url: this.api_url + "sector?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(sector) {
    return axios({
      method: "post",
      url: this.api_url + "sector",
      withCredentials: true,
      data: sector,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  update(sector) {
    return axios({
      method: 'put',
      url: this.api_url + 'sector/',
      withCredentials: true,
      data: sector,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'sector/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
}
