<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '740px' }"
    :header="'LISTA DE VÍNCULOS | ' + personSelected.name"
    :modal="true"
    @hide="hideDialog"
  >
    <Toolbar>
      <template #start>
        <Button
          label="Novo"
          icon="pi pi-plus"
          class="p-button-success"
          @click="showCreate"
        />
      </template>
      <template #end></template>
    </Toolbar>

    <DataTable :value="bonds">
      <template #empty>
        <div class="text-center">Nenhum resultado encontrado...</div>
      </template>
      <Column field="numberBond" header="N° do Vínculo">
        <template #body="slotProps">{{ slotProps.data.numberBond }}</template>
      </Column>
      <Column field="dtExercise" header="Inicio">
        <template #body="slotProps">{{
          $DateTime.formatarDate(slotProps.data.dtExercise)
        }}</template>
      </Column>
      <Column field="dtEnd" header="Término">
        <template #body="slotProps">
          <div v-if="!slotProps.data.dtEnd">
            <b>SEM TÉRMINO</b>
          </div>
          <div v-else>
            {{ $DateTime.formatarDate(slotProps.data.dtEnd) }}
          </div>
        </template>
      </Column>
      <Column field="situation.description" header="Situação">
        <template #body="slotProps">{{
          slotProps.data.situation.description
        }}</template>
      </Column>
      <Column header="Ações">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mr-2"
            @click="showUpdate(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EDITAR'"
          />
          <Button
            icon="pi pi-clock"
            class="p-button-rounded mr-2"
            @click="showWorkday(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA ACESSAR JORNADA DE TRABALHO'"
          />
          <Button
            icon="pi pi-calendar-minus"
            class="p-button-rounded p-button-danger mr-2"
            @click="showHistoricRemoval(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA ACESSAR HISTÓRICO DE AFASTAMENTO'"
          />
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <Button
        label="Fechar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
  <dialog-form-bond
    :personSelected="personSelected"
    :bondSelected="bond"
    :sectors="sectors"
    :offices="offices"
    :occupations="occupations"
    :situations="situations"
    :regiments="regiments"
    :categoryBonds="categoryBonds"
    :typeBonds="typeBonds"
    :typeRemovals="typeRemovals"
    @findAll="getAll()"
  />
  <dialog-workday :bondSelected="bond" @findAll="getData()" />
  <dialog-historic-removal :bondSelected="bond" @findAll="getData()" />
</template>

<script>
//Models
import Bond from "@/models/bond";
import Person from "@/models/person";

//Services
import BondService from "@/service/bond/bond_service";
import PersonService from "@/service/persons/person_service";
import SectorService from "@/service/sector/sector";
import OfficeService from "@/service/office/office_service";
import SituationService from "@/service/situation/situation_service";
import OccupationService from "@/service/occupation/occupation_service";
import CategoryBondService from "@/service/category_bond/category_bond_service";
import RegimentService from "@/service/regiment/regiment_service";
import TypeBondService from "@/service/type_bond/type_bond_service";

//Components
import DialogFormBond from "./dialog-form-bond.vue";
import DialogWorkday from "../workday/dialog-workday.vue";
import DialogHistoricRemoval from "../hystoric_removal/dialog-historic-removal.vue";

export default {
  props: ["personSelected"],
  components: {
    DialogFormBond,
    DialogWorkday,
    DialogHistoricRemoval,
  },
  data() {
    return {
      bondService: new BondService(),
      personService: new PersonService(),
      sectorService: new SectorService(),
      officeService: new OfficeService(),
      situationService: new SituationService(),
      occupationService: new OccupationService(),
      categoryBondService: new CategoryBondService(),
      regimentService: new RegimentService(),
      typeBondService: new TypeBondService(),
      bond: new Bond(),
      person: new Person(),
      bonds: null,
      sectors: [],
      offices: [],
      situations: [],
      occupations: [],
      categoryBonds: [],
      regiments: [],
      typeBonds: [],
      typeRemovals: [],
      selectedBond: null,
      selectAll: false,
      lazyParams: {},
    };
  },
  mounted() {},
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogFormBond;
        if (value === true) {
          this.getAll();
        }
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogFormBond = value;
      },
    },
  },
  methods: {
    getAll() {
      this.getData();
      this.getSectors();
      this.getOffices();
      this.getSituations();
      this.getOccupations();
      this.getCategoryBonds();
      this.getRegiments();
      this.getTypeBonds();
    },
    getData() {
      if (this.personSelected) {
        this.person = this.personSelected;
        this.bondService.findByPersonId(this.person.id).then((response) => {
          if (response.status === 200) {
            this.bonds = response.data;
          } else {
            this.$msgErro(response);
          }
        });
      }
    },
    getSectors() {
      this.loading = true;
      this.sectorService.findAll(this.lazyParams).then((data) => {
        this.sectors = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      });
    },
    getOffices() {
      this.officeService.findAll().then((response) => {
        this.offices = response;
      });
    },
    getSituations() {
      this.situationService.findAll().then((response) => {
        this.situations = response;
      });
    },
    getOccupations() {
      this.occupationService.findAll().then((response) => {
        this.occupations = response;
      });
    },
    getCategoryBonds() {
      this.categoryBondService.findAll().then((response) => {
        this.categoryBonds = response;
      });
    },
    getRegiments() {
      this.regimentService.findAll().then((response) => {
        this.regiments = response;
      });
    },
    getTypeBonds() {
      this.typeBondService.findAll().then((response) => {
        this.typeBonds = response;
      });
    },
    showCreate() {
      this.bond = new Bond();
      this.$store.state.views.bond.dialogForm = true;
    },
    showUpdate(bond) {
      this.bond = bond;
      this.$store.state.views.bond.dialogForm = true;
    },
    showWorkday(bond) {
      this.bond = bond;
      this.$store.state.views.bond.dialogWorkday = true;
    },
    showHistoricRemoval(bond) {
      this.bond = bond;
      this.$store.state.views.bond.dialogHistoricRemoval = true;
    },
    hideDialog() {
      this.bond = new Bond();
      this.submitted = false;
      this.visibleDialog = false;
    },
  },
};
</script>

<style></style>
