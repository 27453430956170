import { required } from "@vuelidate/validators";
import Bond from "./bond";

export default class HistoricRemoval {
  constructor() {
    this.id = null;
    this.dtInit = null;
    this.dtEnd = null;
    this.observation = null;
    this.bond = new Bond();
    this.typeRemoval = null;
  }

  validations() {
    return {     
        dtInit: {
        required,
      },
      typeRemoval: {
        required,    
      },
      bond: {
        id: {
            required,
        },

      },
    };
  }
}
