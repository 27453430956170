<template>
  <div class="p-col-12">
    <Panel header="Gerenciamento de Pessoas">
      <Toolbar>
        <template #start>
          <Button
            label="Novo"
            icon="pi pi-plus"
            class="mr-2"
            @click="showCreate"
          />
        </template>
      </Toolbar>

      <DataTable
        :value="persons"
        :lazy="true"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 30]"
        v-model:filters="filters"
        ref="dt"
        class="p-datatable-sm"
        dataKey="id"
        :totalRecords="totalRecords"
        :loading="loading"
        @page="onPage($event)"
        @filter="onFilter($event)"
        filterDisplay="row"
        responsiveLayout="scroll"
        v-model:selection="selectedPersons"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} pessoas."
      >
        <template #empty>
          <div class="text-center">Nenhum resultado encontrado...</div>
        </template>
        <Column field="key" header="Key" ref="key">
          <template #body="slotProps">
            <small v-if="slotProps.data.key">
              <b>
                {{ slotProps.data.key }}
              </b>
            </small>
            <small v-else>
              <i class="pi pi-key"></i>
            </small>
          </template>
        </Column>

        <Column
          field="name"
          header="Nome"
          filterMatchMode="startsWith"
          ref="name"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Pesquisar por nome"
            />
          </template>

          <template #body="slotProps">
            {{ slotProps.data.name.toUpperCase() }}
          </template>
        </Column>
        <Column
          field="credential"
          header="Matricula"
          filterMatchMode="startsWith"
          ref="credential"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Pesquisar por Matricula"
            />
          </template>
        </Column>
        <Column field="cpf" header="CPF" filterMatchMode="startsWith" ref="cpf">
          <template #filter="{ filterModel, filterCallback }">
            <InputMask
              mask="999.999.999-99"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Pesquisar por CPF"
            />
          </template>
        </Column>
        <Column
          field="contact"
          header="Contato"
          filterMatchMode="startsWith"
          ref="contact"
        >
          <template #body="slotProps">
            <div v-if="slotProps.data.contact">
              {{ slotProps.data.contact }}
            </div>
            <div v-else>
              <b>SEM CONTATO</b>
            </div>
          </template>
        </Column>
        <Column
          field="dtBirdate"
          header="Data de Nascimento"
          filterMatchMode="startsWith"
          ref="dtBirdate"
        >
          <template #body="slotProps">{{
            $DateTime.formatarDate(slotProps.data.dtBirdate)
          }}</template></Column
        >
        <Column header="Ações">
          <template #body="slotProps">
            <Button
              icon="pi pi-list"
              class="p-button-rounded mr-2"
              @click="showBond(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA ACESSAR O VINCULO'"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="showUpdate(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EDITAR'"
            />
            <Button
              icon="pi pi-camera"
              class="p-button-rounded p-button-info mr-2"
              @click="showUpload(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA ENVIAR A FOTO'"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="showRemove(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EXCLUIR'"
            />
          </template>
        </Column>
      </DataTable>
    </Panel>
    <dialog-form :personSelected="person" @findAll="loadLazyData" />
    <dialog-upload :personSelected="person" @findAll="loadLazyData" />
    <dialog-form-bond :personSelected="person" @findAll="getAll" />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
//Models
import Person from "../../models/person";

//Services
import PersonService from "../../service/persons/person_service";
import BondService from "../../service/bond/bond_service";

//Components
import DialogForm from "./components/dialog-form.vue";
import DialogUpload from "./components/dialog-upload.vue";
import DialogFormBond from  "./components/bond/dialog-bond.vue";

export default {
  components: {
    DialogForm,
    DialogUpload,
    DialogFormBond,
  },
  data() {
    return {
      personService: new PersonService(),
      bondService: new BondService(),
      person: new Person(),
      bonds: null,
      loading: false,
      totalRecords: 0,
      persons: null,
      selectedPersons: null,
      selectAll: false,
      filters: {
        name: { value: "", matchMode: "contains" },
        credential: { value: "", matchMode: "contains" },
        cpf: { value: "", matchMode: "contains" },
      },
      lazyParams: {},
      firebase: null,
    };
  },
  mounted() {
    this.firebase = this.$Firebase;
    this.loading = true;
    this.lazyParams = {
      first: 0,
      size: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      name: "",
      cpf: "",
      credential: "",
    };
    this.loadLazyData();
  },
  methods: {
    showCreate() {
      this.person = new Person();
      this.$store.state.views.persons.dialogForm = true;
    },
    showUpdate(person) {
      this.person = person;
      this.$store.state.views.persons.dialogForm = true;
    },
    showUpload(person) {
      this.person = person;
      this.$store.state.views.persons.dialogUpload = true;
    },
    showBond(person) {
      this.person = person;
      this.$store.state.views.bond.dialogFormBond = true;
    },
    showRemove(person) {
      this.person = person;
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          if (this.person.image) {
            this.deleteImage();
          } else {
            this.deleteData();
          }
        },
      });
    },
    deleteImage() {
      this.loading = true;
      this.firebase
        .onDelete(`person/${this.person.id}`)
        .then(() => {
          this.deleteData();
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Alerta de Erro.",
            detail: err,
            life: 6000,
          });
        });
    },
    deleteData() {
      this.personService
        .delete(this.person.id)
        .then((data) => {
          this.$msgSuccess(data);
          this.loadLazyData();
        })
        .catch((error) => {
          this.$msgErro(error);
          this.loading = false;
          //console.error(error);
        });
    },
    loadLazyData() {
      this.loading = true;

      //LIMPEZA DE FILTROS
      this.lazyParams.name = this.lazyParams.name == null ? "" : this.lazyParams.name;
      this.lazyParams.credential = this.lazyParams.credential == null ? "" : this.lazyParams.credential;
      this.lazyParams.cpf = this.lazyParams.cpf == null ? "" : this.lazyParams.cpf;
      // FIM LIMPEZA DE FILTROS

      this.personService.searchPerson(this.lazyParams).then((data) => {
        this.persons = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      });
    },
    onPage(event) {
      this.lazyParams.page = event.page;
      this.lazyParams.size = event.rows;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.page = 0;
      this.lazyParams.name = this.filters.name.value;
      this.lazyParams.credential = this.filters.credential.value;
      this.lazyParams.cpf = this.filters.cpf.value;
      this.loadLazyData();
    },
  },
};
</script>

<style></style>
