import { required } from "@vuelidate/validators";
import { numeric } from "vuelidate/lib/validators";
import Bond from "./bond";
export default class Workday {
    constructor() {
        this.id = null;
        this.entrance = null;
        this.exit = null;
        this.ch = null;
        this.breakTime = null;
        this.dtStart = null;
        this.dtEnd = null;
        this.description = null;
        this.bond = new Bond();
    }
    validations() {
        return {
            entrance: {
                required,
            },

            exit: {
                required,
            },

            breakTime: {
                required,
            },

            ch: {
                required,
                numeric: numeric
            },

            dtStart: {
                required,
            },

            bond: {
                id: {
                    required,
                },
            },
        };
    }
}