<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '1080px' }"
    header="Formulário de Vínculo"
    :modal="true"
    @hide="hideDialog"
  >
    <Fieldset legend="Informações do Servidor">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-5">
          <label for="person">Servidor:</label>
          <InputText id="person" v-model="bond.person.name" disabled />
        </div>
        <div class="field col-12 md:col-5">
          <label for="regiment">Regime:</label>
          <Dropdown
            v-model="v$.bond.regiment.$model"
            :options="regiments"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar Setor de Atuação"
            id="regiment"
            :class="{ 'p-invalid': submitted && v$.bond.regiment.id.$invalid }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.regiment.id.$invalid"
            >Regime é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-2">
          <label for="numberBond">N° do Vinculo:</label>
          <InputText
            id="numberBond"
            v-model="v$.bond.numberBond.$model"
            maxlength="255"
            placeholder="N° do vínculo"
            :class="{ 'p-invalid': submitted && v$.bond.numberBond.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.bond.numberBond.$invalid"
            >N° do Vínculo é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="dtExercise">Data de Exercício:</label>
          <InputText
            id="dtExercise"
            v-model="v$.bond.dtExercise.$model"
            maxlength="255"
            type="date"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && v$.bond.dtExercise.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.bond.dtExercise.$invalid"
            >Data de Exercício é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="dtInit">Data da Pose:</label>
          <InputText
            id="dtInit"
            v-model="v$.bond.dtInit.$model"
            maxlength="255"
            type="date"
            placeholder="Digite o n° do vínculo"
            :class="{ 'p-invalid': submitted && v$.bond.dtInit.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.bond.dtInit.$invalid"
            >Data da Pose é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="dtEnd">Data de Término:</label>
          <InputText
            id="dtEnd"
            v-model="bond.dtEnd"
            maxlength="255"
            type="date"
            placeholder="Digite o n° do vínculo"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label for="acting">Atuação:</label>
          <AutoComplete
            v-model="actingInput"
            :suggestions="sectors"
            @complete="searchSectors"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            placeholder="Digite nome ou sigla do setor"
            :class="{ 'p-invalid': submitted && v$.bond.acting.id.$invalid }"
            dropdown
          />
          <small class="p-error" v-if="submitted && v$.bond.acting.id.$invalid"
            >Setor de Atuação é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label for="capacity">Lotação:</label>
          <AutoComplete
            v-model="capacityInput"
            :suggestions="sectors"
            @complete="searchSectors"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            placeholder="Digite nome ou sigla setor"
            :class="{ 'p-invalid': submitted && v$.bond.capacity.id.$invalid }"
            dropdown
          />

          <small
            class="p-error"
            v-if="submitted && v$.bond.capacity.id.$invalid"
            >Setor de Lotação é obrigatório.</small
          >
        </div>
      </div>
    </Fieldset>
    <br />
    <Fieldset legend="Informações do Vínculo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="situation">Situação:</label>
          <Dropdown
            v-model="v$.bond.situation.$model"
            :options="situations"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar a Situação"
            id="situation"
            :class="{ 'p-invalid': submitted && v$.bond.situation.id.$invalid }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.situation.id.$invalid"
            >Situação é obrigatório.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="typeBond">Tipo do Vinculo:</label>
          <Dropdown
            v-model="v$.bond.typeBond.$model"
            :options="typeBonds"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar a função"
            id="typeBond"
            :class="{ 'p-invalid': submitted && v$.bond.typeBond.id.$invalid }"
          />
          <small class="p-error" v-if="submitted && v$.bond.typeBond.$invalid"
            >Tipo do Vinculo é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="categoryBond">Categoria de Vinculo:</label>
          <Dropdown
            v-model="v$.bond.categoryBond.$model"
            :options="categoryBonds"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar a função"
            id="categoryBond"
            :class="{
              'p-invalid': submitted && v$.bond.categoryBond.id.$invalid,
            }"
          />
          <small
            class="p-error"
            v-if="submitted && v$.bond.categoryBond.id.$invalid"
            >Categoria do Vinculo é obrigatória.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="office">Cargo:</label>
          <Dropdown
            v-model="bond.office"
            :options="offices"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar o Cargo"
            id="office"
            @change="isSectorValid"
            :class="{
              'p-invalid': (submitted && !bond.office.id) || isSameSector,
            }"
          />
          <small class="p-error" v-if="submitted && !bond.office.id"
            >Cargo é obrigatório</small
          >
          <small class="p-error" v-if="isSameSector"
            >Campos de Cargo e Função não podem ser iguais.</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for="occupation">Função:</label>
          <Dropdown
            v-model="bond.occupation"
            :options="occupations"
            filter="true"
            optionLabel="description"
            placeholder="Selecionar a função"
            id="occupation"
            @change="isSectorValid"
            :class="{
              'p-invalid': (submitted && !bond.occupation.id) || isSameSector,
            }"
          />
          <small class="p-error" v-if="submitted && !bond.occupation.id"
            >Função é obrigatório</small
          >
          <small class="p-error" v-if="isSameSector"
            >Campos de Cargo e Função não podem ser iguais.</small
          >
        </div>
      </div>
    </Fieldset>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.bond.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog()"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Bond from "@/models/bond";
import Person from "@/models/person";

//Services
import BondService from "@/service/bond/bond_service";
import SectorService from "@/service/sector/sector";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: [
    "bondSelected",
    "situations",
    "offices",
    "typeBonds",
    "categoryBonds",
    "occupations",
    "regiments",
    "personSelected",
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      bond: new Bond(),
      person: new Person(),
      submitted: false,
      bondService: new BondService(),
      sectorService: new SectorService(),
      sectors: null,
      isSameSector: false,
      lazyParams: {},
      filteredItems: null,
      actingInput: "",
      capacityInput: "",
    };
  },
  created() {},
  mounted() {},
  validations() {
    return {
      bond: new Bond().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogForm = value;
      },
    },
  },
  methods: {
    getData() {
      if (this.bondSelected) {
        this.bond = this.bondSelected;
        this.bond.person = this.personSelected;
        this.actingInput =
          this.bond.acting.id == null ? this.actingInput : this.bond.acting;
        this.capacityInput =
          this.bond.capacity.id == null
            ? this.capacityInput
            : this.bond.capacity;
      }
    },
    isSectorValid() {
      // TODO: OFFICE AND OCCUPATION IS NULL
      if (this.bond.office.id != null && this.bond.occupation.id != null) {
        const office = this.bond.office.description;
        const occupation = this.bond.occupation.description;
        if (office === occupation) {
          this.isSameSector = true;
        } else {
          this.isSameSector = false;
        }
      }
    },
    send(isFormValid) {
      this.submitted = true;
      this.bond.acting = this.actingInput;
      this.bond.capacity = this.capacityInput;
      if (isFormValid && this.isSameSector === false) {
        if (this.bond.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.bondService
        .create(this.bond)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.bondService
        .update(this.bond)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.bond = new Bond();
      this.submitted = false;
      this.actingInput = "";
      this.capacityInput = "";
      this.visibleDialog = false;
    },
    searchSectors(event) {
      this.lazyParams = {
        name: event.query,
        size: 100,
      };
      this.sectorService.findAll(this.lazyParams).then((data) => {
        if (data.content.length === 0) {
          //console.log("NÃO AXOU POR NOME! BUSCANDO POR SIGLA...")
          this.lazyParams = {
            acronym: event.query,
            size: 100,
          };
          this.sectorService.findAll(this.lazyParams).then((data2) => {
            this.sectors = data2.content;
          });
        } else {
          //console.log("AXOU POR NOME...")
          this.sectors = data.content;
        }
      });
    },
  },
};
</script>
<style scoped></style>
