import axios from "axios";

import Token from "../../utilities/jwt/Token.js";

export default class OfficeService extends Token {
  
  findAll() {
    return axios({
      method: "get",
      url: this.api_url + "office",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(office) {
    return axios({
      method: "post",
      url: this.api_url + "office",
      withCredentials: true,
      data: office,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }
  
  update(office) {
    return axios({
      method: 'put',
      url: this.api_url + 'office/' + office.id,
      withCredentials: true,
      data: office,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'office/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
}
