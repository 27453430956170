import axios from "axios";

import Token from "../../utilities/jwt/Token.js"

export default class HistoricRemovalService extends Token {
  findByBondId(bondId) {
    return axios({
      method: 'get',
      url: this.api_url + 'historic-removal/' + bondId,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res);
  }

  findAll() {
    return axios({
      method: 'get',
      url: this.api_url + 'historic-removal',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  create(workday) {
    return axios({
      method: 'post',
      url: this.api_url + 'historic-removal',
      withCredentials: true,
      data: workday,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }

  update(workday) {
    return axios({
      method: 'put',
      url: this.api_url + 'historic-removal/' + workday.id,
      withCredentials: true,
      data: workday,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'historic-removal/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

}
