import axios from "axios";

import Token from "../../utilities/jwt/Token.js"

export default class TypeRemovalService extends Token {
  findAll() {
    return axios({
      method: 'get',
      url: this.api_url + 'type-removal',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  create(typeRemoval) {
    return axios({
      method: 'post',
      url: this.api_url + 'type-removal',
      withCredentials: true,
      data: typeRemoval,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }

  update(typeRemoval) {
    return axios({
      method: 'put',
      url: this.api_url + 'type-removal/' + typeRemoval.id,
      withCredentials: true,
      data: typeRemoval,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'type-removal/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

}
