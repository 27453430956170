import axios from "axios";

import Token from "../../utilities/jwt/Token.js"

export default class BondService extends Token {

  findByPersonId(personId) {
    return axios({
      method: 'get',
      url: this.api_url + 'bond/' + personId + '/person',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res);
  }

  findById(id) {
    return axios({
      method: 'get',
      url: this.api_url + 'bond/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res);
  }

  create(bond) {
    return axios({
      method: 'post',
      url: this.api_url + 'bond',
      withCredentials: true,
      data: bond,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }

  update(bond) {
    return axios({
      method: 'put',
      url: this.api_url + 'bond/' + bond.id,
      withCredentials: true,
      data: bond,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'bond/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }

}
