<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '640px' }"
    header="Formulário de Historico"
    :modal="true"
    @hide="hideDialog"
  >
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-4">
        <label for="typeRemoval">Tipo de Afastamento</label>
        <Dropdown
          id="typeRemoval"
          v-model="v$.historicRemoval.typeRemoval.$model"
          @click="getRemovals()"
          :options="removals"
          optionLabel="initials"
          placeholder="Selecione o Tipo de Afastamento"
          :class="{
            'p-invalid': submitted && v$.historicRemoval.typeRemoval.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.historicRemoval.typeRemoval.$invalid"
          >Tipo de Afastamento e obrigatória.</small
        >
      </div>
      <div class="field col-12 md:col-4">
        <label for="dtStart">Data Início</label>
        <InputText
          id="dtStart"
          v-model="v$.historicRemoval.dtInit.$model"
          type="date"
          placeholder="Digite o n° do vínculo"
          :class="{
            'p-invalid': submitted && v$.historicRemoval.dtInit.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.historicRemoval.dtInit.$invalid"
          >Inicio é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-4">
        <label for="dtEnd">Data Fim</label>
        <InputText
          id="dtEnd"
          v-model="historicRemoval.dtEnd"
          type="date"
          placeholder="Digite o n° do vínculo"
        />
        <!-- </div>
          <div class="field col-12 md:col-4">
            <label for="exit">Observação</label>
            <Dropdown
              id="exit"
              v-model="v$.historicRemoval.exit.$model"
              type="time"
              placeholder="Digite o tipo de observação"
              :class="{ 'p-invalid': submitted && v$.historicRemoval.exit.$invalid }"
            />
            <small class="p-error" v-if="submitted && v$.historicRemoval.exit.$invalid"
              >Observação e obrigatória.</small
            > -->
        <!-- </div>
          <div class="field col-12 md:col-4">
            <label for="breakTime">Intervalo</label>
            <InputText
              id="breakTime"
              v-model="v$.historicRemoval.breakTime.$model"
              type="time"
              placeholder="Digite o n° do vínculo"
              :class="{ 'p-invalid': submitted && v$.historicRemoval.breakTime.$invalid }"
            />
            <small
              class="p-error"
              v-if="submitted && v$.historicRemoval.breakTime.$invalid"
              >Intervalo é obrigatório.</small
            > -->
        <!-- </div>
          <div class="field col-12 md:col-4">
            <label for="ch">Carga Horária (Diária)</label>
            <InputNumber
              inputId="ch"
              v-model="v$.historicRemoval.ch.$model"
              mode="decimal"
              :min="4"
              :max="12"
              showButtons
              :class="{ 'p-invalid': submitted && v$.historicRemoval.ch.$invalid }"
            />
            <small class="p-error" v-if="submitted && v$.historicRemoval.ch.$invalid"
              >Carga Horária é obrigatória.</small
            > -->
      </div>
      <div class="field col-12 md:col-12">
        <label for="description">Observação</label>
        <Textarea
          id="description"
          v-model="historicRemoval.observation"
          placeholder="Digite a descrição"
        />
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.historicRemoval.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog()"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Bond from "@/models/bond";
import HistoricRemoval from "@/models/historic_removal";

//Services
import HistoricRemovalService from "@/service/historic_removal/historic_removal_service";
import TypeRemovalService from "@/service/type_removal/type_removal_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["bondSelected", "historicRemovalSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      historicRemoval: new HistoricRemoval(),
      bond: new Bond(),
      submitted: false,
      removals: [],
      historicRemovalService: new HistoricRemovalService(),
      typeRemovalService: new TypeRemovalService(),
    };
  },
  mounted() {},
  created() {},
  validations() {
    return {
      historicRemoval: new HistoricRemoval().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.bond.dialogFormHistoricRemoval;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.bond.dialogFormHistoricRemoval = value;
      },
    },
  },
  methods: {
    getData() {
      if (this.bondSelected) {
        this.historicRemoval.bond = this.bondSelected;
        this.historicRemoval = this.historicRemovalSelected;
        this.historicRemoval.dtInit = this.$DateTime.formatarDateInput(
          this.historicRemoval.dtInit
        );
        this.historicRemoval.dtEnd = this.$DateTime.formatarDateInput(
          this.historicRemoval.dtEnd
        );
      }
    },
    send(isFormValid) {
      this.submitted = true;

      if (isFormValid) {
        if (this.historicRemoval.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.historicRemovalService
        .create(this.historicRemoval)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.historicRemovalService
        .update(this.historicRemoval)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    getRemovals() {
      this.typeRemovalService.findAll().then((response) => {
        this.removals = response;
        console.log(this.removals);
      });
    },
    hideDialog() {
      this.historicRemoval = new HistoricRemoval();
      this.submitted = false;
      this.$emit("findAll");
      this.visibleDialog = false;
    },
  },
};
</script>
<style scoped></style>
